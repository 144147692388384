import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import {
	LandingPageBestSellers,
	LandingPageHero,
	Layout,
	LandingPageBadges,
	LandingPageFeaturedProducts,
	LandingPageOurBrands,
	LandingPageFeaturedBrand,
} from '../components'
import { v4 as uuid } from 'uuid'
import { LandingPageTestimonials } from '../components/LandingPage/Testimonials'
import {
	parseCMSProducts,
	getLayoutDataFromWebsiteData,
	getWebsiteData,
} from '../utils'

const Index = ({ location, data }) => {
	const cmsData = getWebsiteData(data)
	const pageData = cmsData.homePage
	const layoutData = getLayoutDataFromWebsiteData(cmsData)

	const heroProps = pageData.hero
	const bestSellerProps = {
		title: pageData.bestsellersTitle,
		shadow: pageData.bestsellersTitleShadow,
		products: parseCMSProducts(pageData.bestsellersProducts),
	}

	const badgesProps = pageData.badges.map((badge) => ({
		...badge,
		icon: badge.icon.asset,
		title: badge.name,
	}))

	const featuredProductsProps = {
		products: parseCMSProducts(pageData.spotlightProducts),
		title: pageData.spotlightTitle,
		shadow: pageData.spotlightTitleShadow,
		banner: pageData.spotlightImage.asset,
	}

	const ourBrandsProps = {
		title: pageData.brandsTitle,
		shadow: pageData.brandsTitleShadow,
		redirectText: pageData.brandsTitleRedirectText,
		brands: pageData.brandsLogos.map((brand) => ({
			_key: uuid(),
			isWide: brand.isWideLogo,
			logo: brand.image.asset,
		})),
	}

	const testimonialProps = {
		title: pageData.quotesTitle,
		shadow: pageData.quotesTitleShadow,
		testimonials: pageData.quotes.map((quote) => ({
			...quote,
			person: quote.name,
			logo: quote.companyBrand.image.asset,
			isWideLogo: quote.companyBrand.isWideLogo,
		})),
	}

	const featuredBrandProps = {
		title: pageData.brandShowcaseTitle,
		subtitle: pageData.brandShowcaseSubtitle,
		background: pageData.brandShowcaseBackground,
		buttonTitle: pageData.brandShowcaseButtonTitle,
		buttonRedirect: pageData.brandShowcaseButtonRedirect,
		images: pageData.brandShowcaseImages,
		logo: pageData.brandShowcaseLogo,
	}

	return (
		<Layout
			location={location}
			SeoProps={{ title: pageData.name, description: pageData.description }}
			{...layoutData}
			disableContactUs
		>
			<LandingPageHero data={heroProps} />
			<LandingPageBestSellers data={bestSellerProps} />
			<LandingPageBadges data={badgesProps} />
			<LandingPageFeaturedProducts data={featuredProductsProps} />
			<LandingPageFeaturedBrand data={featuredBrandProps} />
			<LandingPageOurBrands data={ourBrandsProps} />
			<LandingPageTestimonials data={testimonialProps} />
		</Layout>
	)
}
Index.propTypes = {
	location: PropTypes.object,
	data: PropTypes.any,
}
export default Index

export const query = graphql`
	query q {
		allSanityWebsite(filter: { name: { eq: "Superba Website" } }) {
			edges {
				node {
					workingHours
					whatsappNumber
					socialsLinkedin
					socialsInstagram
					socialsFacebook
					phoneNumber
					waze
					email
					contactUsTitleShadow
					digitalCatalogueLocation
					contactUsTitle
					contactUsContent
					buttonContent
					address
					homePage {
						name
						description
						badges {
							_key
							icon {
								asset {
									gatsbyImageData(layout: CONSTRAINED)
									title
								}
							}
							description
							name
						}
						bestsellersTitle
						bestsellersTitleShadow
						bestsellersProducts {
							image {
								asset {
									gatsbyImageData(layout: CONSTRAINED)
								}
								_key
							}
							subtitle
							redirect
							name
						}
						spotlightImage {
							asset {
								gatsbyImageData(layout: FULL_WIDTH)
							}
						}
						spotlightTitle
						spotlightTitleShadow
						spotlightProducts {
							redirect
							subtitle
							name
							image {
								asset {
									gatsbyImageData(layout: CONSTRAINED)
								}
							}
							_key
						}
						quotesTitle
						quotesTitleShadow
						quotes {
							_key
							name
							position
							quote
							company
							companyBrand {
								isWideLogo
								image {
									_key
									asset {
										gatsbyImageData(layout: CONSTRAINED)
									}
								}
							}
						}
						brandShowcaseButtonTitle
						brandShowcaseButtonRedirect
						brandShowcaseBackground
						brandShowcaseSubtitle
						brandShowcaseTitle
						brandShowcaseLogo {
							image {
								asset {
									gatsbyImageData(layout: CONSTRAINED)
								}
							}
							isWideLogo
						}
						brandShowcaseImages {
							asset {
								_key
								gatsbyImageData(layout: CONSTRAINED)
							}
						}
						brandsTitle
						brandsTitleShadow
						brandsTitleRedirectText
						brandsLogos {
							_key
							isWideLogo
							image {
								asset {
									gatsbyImageData(layout: CONSTRAINED)
								}
							}
						}
						hero {
							button
							buttonEmail
							buttonRedirect
							_key
							image {
								asset {
									gatsbyImageData(layout: FULL_WIDTH)
								}
							}
							title
							subtitle
							pretitle
						}
					}
				}
			}
		}
	}
`
