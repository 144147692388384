import cn from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import LeftQuotationMark from '../../../assets/icons/left_quote.inline.svg'
import { FullWidthCarousel, Logo, Section, Title } from '../../Shared'
import {
	quote as quoteClass,
	section as sectionClass,
} from './Testimonials.module.scss'

const LeftQuote = () => (
	<LeftQuotationMark
		className="position-absolute"
		style={{ left: 0, top: 0 }}
	/>
)
const RightQuote = () => (
	<LeftQuotationMark
		className="position-absolute"
		style={{ right: 0, bottom: 0, transform: 'rotate(180deg)' }}
	/>
)

const Quote = ({ quote, logo, person, position, isWideLogo, company }) => {
	return (
		<div className="d-flex flex-column align-items-center justify-content-center text-center text-white px-3 px-lg-5">
			<p className={cn('mb-5 position-relative', quoteClass)}>
				<LeftQuote />
				{quote}
				<RightQuote />
			</p>
			<Logo
				wideLogoWidth={120}
				squaredLogoWidth={60}
				logo={logo}
				altText="company logo"
				isWide={isWideLogo}
			/>
			<p className="my-3 fw-bold">{person}</p>
			<p className="mb-1">{position}</p>
			<p className="fw-lighter">{company}</p>
		</div>
	)
}

Quote.propTypes = {
	quote: PropTypes.string.isRequired,
	logo: PropTypes.object,
	isWideLogo: PropTypes.bool,
	person: PropTypes.string.isRequired,
	position: PropTypes.string.isRequired,
	company: PropTypes.string.isRequired,
}
Quote.defaultProps = {
	logo: null,
	isWideLogo: true,
}

const Testimonials = ({ data = {} }) =>
	!!(data.testimonials && data.testimonials.length) && (
		<Section variant="dark" fullWidth className={sectionClass}>
			<Title align="center" variant="white" titleShadow={data.shadow}>
				{data.title}
			</Title>
			<FullWidthCarousel interval={null} color="red">
				{(data.testimonials || []).map(({ _key, ...quote }) => (
					<Quote key={_key} {...quote} />
				))}
			</FullWidthCarousel>
		</Section>
	)

Testimonials.propTypes = {
	data: PropTypes.exact({
		title: PropTypes.string,
		shadow: PropTypes.string,
		testimonials: PropTypes.arrayOf(
			PropTypes.shape({
				_key: PropTypes.string,
				quote: PropTypes.string,
				logo: PropTypes.object,
				isWideLogo: PropTypes.bool,
				person: PropTypes.string,
				position: PropTypes.string,
				company: PropTypes.string,
			})
		),
	}).isRequired,
}

export default Testimonials
